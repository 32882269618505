import styled from 'styled-components';

export const Container = styled.nav`
  width: 49%;
  height: 315px;

  .card {
    width: 100% !important;
    height: 100% !important;
    overflow: auto;

    /* width */
    ::-webkit-scrollbar {
      width: 6px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #f9fbfb;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #dee2e6;

      transition: 0.2s;
    }

    .list-group-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 90px;
    }

    .icon {
      height: 100%;
      font-size: 30px;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #57069e;
    }
    .item {
      margin-left: 20px;
    }
    .item p:first-child {
      font-size: 15px;
    }
    .item a:first-child {
      font-size: 15px;
      text-decoration: none;
    }
    .item a:hover {
      text-decoration: underline;
    }
    .item a svg {
      margin-left: 5px;
    }

    .card-header {
      font-size: 16px;
      font-weight: 500;
    }
  }

  p {
    margin-bottom: 0;
  }

  @media screen and (max-width: 992px) {
    .icon {
      font-size: 35px;
    }

    .titulo {
      padding: 5px;
      h5 {
        font-size: 1rem;
      }
    }

    .container-item a {
      font-size: 14px;
    }

    .container-item p {
      font-size: 13px;
    }
  }
`;
