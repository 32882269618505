import styled from 'styled-components';

export const Container = styled.nav`
  p {
    margin-bottom: 0;
  }
  width: 100%;
  height: 90px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 6px;

  .notification {
    border-right: 1px solid rgba(0, 0, 0, 0.125);
    height: 80%;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #57069e;
  }

  .icon {
    height: 90px;
    font-size: 40px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #57069e;
  }

  .painel {
    height: 80%;
  }

  .icon-arrow {
    height: 90px;
    font-size: 45px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    border: none;
    outline-style: none;
    color: #57069e;
  }

  .icon-arrow:disabled {
    height: 90px;
    font-size: 45px;
    cursor: not-allowed;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    border: none;
    outline-style: none;
    color: #860af2;
  }

  .icon-prioridade {
    height: 90px;
    font-size: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: red;
  }

  .container-item {
    height: 90px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    animation: moveToLeft 0.5s linear;
    opacity: 1;
  }

  @keyframes moveToLeft {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .container-item h3 {
    margin-top: 10px;
  }
  .container-item h5 {
    margin-top: 10px;
  }
  .container-item p {
    margin-top: -10px;
    margin-bottom: 10px;
  }

  @media screen and (max-width: 992px) {
    .notification {
      background-color: inherit;

      &.icon {
        font-size: 30px;
      }
    }
  }
`;
