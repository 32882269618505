import { lighten } from 'polished';
import styled from 'styled-components';

export const Container = styled.nav`
  width: 100%;
  height: 315px;
  overflow: auto;

  .card {
    width: 100% !important;
    height: 100% !important;
    overflow: auto;

    /* width */
    ::-webkit-scrollbar {
      width: 6px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #f9fbfb;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #dee2e6;

      transition: 0.2s;
    }

    .list-group-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 50%;
      height: 49px;
      border: 0px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    }

    .icon {
      height: 100%;
      font-size: 25px;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 52%;
      color: #57069e;
    }
    .item {
      margin-left: 20px;
    }
    .item p:first-child {
      font-size: 15px;
    }
    .item a:first-child {
      font-size: 15px;
      text-decoration: none;
    }
    .item a:hover {
      text-decoration: underline;
    }
    .item a svg {
      margin-left: 5px;
    }
    .card-header {
      font-size: 16px;
      font-weight: 500;
    }
  }

  p {
    margin-bottom: 0;
  }

  .icon {
    font-size: 25px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 52%;
  }

  .container-item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 52%;
  }

  .container-item p {
    margin-top: -5px;
    font-size: 15px;
    line-height: 15px;
    margin-right: 5px;
  }

  .containerFlex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .link {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      cursor: pointer;
    }
  }

  .containerFlex .row {
    border-top: 1px solid black;
    height: 50px;
    width: 50%;
  }
  .containerFlex .row:nth-child(-n + 2) {
    border-top: 0;
  }
  .containerFlex a {
    color: inherit;
    text-decoration: inherit;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .container-empty {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    padding: 30px;
  }

  .loading {
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
