import React, { useEffect, useState, useCallback, useContext } from 'react';

import { Container } from './styles';
import { Col, Row } from 'react-bootstrap';
import PainelNotificacao from './Components/PainelNotificacao';
import PainelArtigo from './Components/PainelArtigo';
import VisitadosRecentemente from './Components/VisitadosRecentemente';
import PainelMidias from './Components/PainelMidias';
import { BsLifePreserver } from 'react-icons/bs';
import api from '~/services/api';
import {
  NavigationProps,
  INotificacao,
  RecentesProps,
  PainelNotificacaoProps,
} from './types';

const Dashboard: React.FC<NavigationProps> = ({ navmenu, activated }) => {
  const [recentes, setRecentes] = useState<any>('');
  const [notificacoes, setNotificacoes] = useState<INotificacao[]>([]);
  const [messageApi, setMessage] = useState<string>('');

  const welcomeKonvix = [
    {
      title: 'Primeiros Passos',
      desc: 'Aprenda a como tirar o máximo do ERP',
      url: 'https://konvix.zendesk.com/hc/pt-br/articles/5644884397467-Primeiro-contato-com-o-ERP-Cloud',
      icon: 'IoIosRocket',
      lib_icon: 'Io',
      id: 1,
    },
    {
      title: 'Criar, Editar e Excluir Registros',
      desc: 'Como manipular registros no ERP',
      url: 'https://konvix.zendesk.com/hc/pt-br/articles/5863117546779-Opera%C3%A7%C3%B5es-Padr%C3%A3o-Inserir-Editar-e-Excluir-um-Registro',
      icon: 'MdModeEdit',
      lib_icon: 'Md',
      id: 2,
    },
    {
      title: 'Como Criar Consultas',
      desc: 'Crie e resgate consultas dinâmicas',
      url: 'https://konvix.zendesk.com/hc/pt-br/articles/5664437504923-Cart%C3%A3o-de-consultas-pesquisas-a-um-clique',
      icon: 'FaSearch',
      lib_icon: 'Fa',
      id: 3,
    },
  ];

  useEffect(() => {
    async function getNotificacao() {
      const req = await api.post('/central-de-notificacoes/notificacoes', {
        tipo_notificacao_filtro: 0,
        des_pesquisa: '',
        orderBy: ['dta_cadastro desc, cod_notificacao desc'],
        start: 0,
        tipo_notificacao_prioridade: -1,
        limit: 10,
      });
      const { data, message }: PainelNotificacaoProps = req.data;
      setMessage(message);
      setNotificacoes(data);
    }
    getNotificacao();
  }, []);

  const configuracaoInicial = [
    {
      title: 'Regras de Loja',
      desc: 'Defina as métricas utilizadas na gestão da loja.',
      url: '/regras-de-loja',
      icon: 'FaCogs',
      lib_icon: 'Fa',
      id: 1,
    },
    {
      title: 'Certificado Digital',
      desc: 'Configure as características de emissão de suas NF-e.',
      url: '/certificado-digital',
      icon: 'FaFileSignature',
      lib_icon: 'Fa',
      id: 2,
    },
    {
      title: 'Controle de Acesso',
      desc: 'Defina quais telas e funcionalidades cada grupo de usuários terá acesso.',
      url: '/controle-de-acesso',
      icon: 'FaUserCog',
      lib_icon: 'Fa',
      id: 3,
    },
  ];

  const painelMidiaContent = [
    {
      titulo: 'Novidades',
      subtitulo: 'Saiba como negociar com seus fornecedores',
      iframe: 'https://youtube.com/embed/9F9AnVM4cZ8',
      url: 'https://youtu.be/9F9AnVM4cZ8',
      id: 1,
      id_video: '9F9AnVM4cZ8',
      thumb: 'https://img.youtube.com/vi/9F9AnVM4cZ8/maxresdefault.jpg',
    },
    {
      titulo: 'Novidades',
      subtitulo: 'Não quebre a sua loja',
      iframe: 'https://www.youtube.com/embed/hlqA36zCMKI',
      url: 'https://youtu.be/hlqA36zCMKI',
      id: 2,
      id_video: 'hlqA36zCMKI',
      thumb: 'https://img.youtube.com/vi/hlqA36zCMKI/maxresdefault.jpg',
    },
  ];

  const getUrl = useCallback(
    (id: number) => {
      if (navmenu) {
        const url = navmenu.filter((screen: any) => screen.id === id);
        return url[0]?.url;
      }
    },
    [navmenu],
  );

  useEffect(() => {
    (async () => {
      if (activated) {
        const { data } = await api.get(`/recentes`);
        if (data.success && data.data.length > 0) {
          const options = data.data.map((tela: RecentesProps) => ({
            cod_tela: tela.cod_tela,
            label_menu: tela.des_tela,
            lib_icon: tela.lib_icon,
            des_icon: tela.des_icon,
            url: getUrl(tela.cod_tela),
            link_material: tela.link_material,
            flg_abre_pesquisa: tela.flg_abre_pesquisa,
          }));

          setRecentes(options);
        }
      }
    })();
  }, [activated]);

  return (
    <Container>
      <Row>
        <Col md={12} sm={12}>
          <PainelNotificacao data={notificacoes} message={messageApi} />
        </Col>
      </Row>
      <Row>
        <Col md={12} sm={12}>
          <div className="ContainerFlex">
            <div className="containerLeft">
              <div className="box-artigo">
                <PainelArtigo
                  data={welcomeKonvix}
                  title="Bem-vindo ao Konvix ERP"
                />
                <PainelArtigo
                  data={configuracaoInicial}
                  title="Configuração Inicial"
                  interno
                />
              </div>
              <div className="box-large">
                <VisitadosRecentemente
                  data={recentes}
                  title="Visitados Recentemente"
                />
              </div>
            </div>
            <div className="containerRight">
              <PainelMidias data={painelMidiaContent} />
            </div>
          </div>
        </Col>
      </Row>

      <Row>
        <Col md={12} sm={12}>
          <div className="footer">
            <a
              href="https://konvix.zendesk.com/hc/pt-br"
              target="_blank"
              rel="noreferrer"
            >
              <BsLifePreserver />
              <p>Central de Ajuda!</p>
            </a>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Dashboard;
