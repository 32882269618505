import React from 'react';

import { Container } from './styles';
import icones from '~/config/icones';
import { Card } from 'react-bootstrap';
import ListGroup from 'react-bootstrap/ListGroup';
import { MdOutlineOpenInNew } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { nanoid } from 'nanoid';
import { PainelArtigoProps } from '../../types';

const PainelArtigo: React.FC<PainelArtigoProps> = ({
  title,
  data,
  interno,
}) => {
  const getIcon = (libIcon: any, iconDes: any, key: any) => {
    const iconkey = Object.keys(icones).find((icon) => icon === libIcon);
    let Icon = '';
    if (iconkey) {
      const lib = icones[iconkey];
      Icon = lib[iconDes];
    }
    return (
      <>
        <div key={key}>
          <Icon />
        </div>
      </>
    );
  };

  return (
    <Container>
      <Card>
        <Card.Header>{title}</Card.Header>
        <ListGroup variant="flush">
          {data.map((element: any, index: any) => {
            return (
              <ListGroup.Item key={nanoid()}>
                <div className="icon">
                  {getIcon(element.lib_icon, element.icon, index)}
                </div>
                <div className="item">
                  {!interno && (
                    <a href={element.url} target="_blank" rel="noreferrer">
                      {element.title}
                      <MdOutlineOpenInNew />
                    </a>
                  )}

                  {interno && (
                    <Link to={element.url} key={element.label_menu}>
                      <p>{element.title}</p>
                    </Link>
                  )}
                  <p>{element.desc}</p>
                </div>
              </ListGroup.Item>
            );
          })}
        </ListGroup>
      </Card>
    </Container>
  );
};

export default PainelArtigo;
