import React from 'react';

import { Container } from './styles';
import { PainelMidiasProps } from '../../types';

const PainelMidias: React.FC<PainelMidiasProps> = ({ data }) => {
  return (
    <Container>
      {data.map((element: any) => {
        return (
          <div className="box-video" key={element.id}>
            <div className="video">
              <a href={element.url} target="_blank" rel="noreferrer">
                <img
                  src={element.thumb}
                  alt="YouTube video thumbnail"
                  style={{
                    cursor: 'pointer',
                    border: '0px',
                    width: '100%',
                    height: 'auto',
                  }}
                />
              </a>

              <div className="textVideo">
                <a href={element.url} target="_blank" rel="noreferrer">
                  <h5>Novidades</h5>
                </a>
                <p>{element.subtitulo}</p>
              </div>
            </div>
          </div>
        );
      })}
    </Container>
  );
};

export default PainelMidias;
