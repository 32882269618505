import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;

  .ContainerFlex {
    display: flex;
    margin-top: 20px;
    height: 700px;
  }

  .containerLeft {
    flex: 2;
    height: 91%;
    display: flex;
    flex-direction: column;
  }

  .box-artigo {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .box-large {
    width: 100%;
    margin-top: 20px;
  }

  .containerRight {
    flex: 1;
    height: 93%;
    overflow-x: auto;
    margin-left: 15px;

    /* width */
    ::-webkit-scrollbar {
      width: 6px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #f9fbfb;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #dee2e6;

      transition: 0.2s;
    }
  }

  .footer {
    font-size: 60px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: #57069e;
  }

  .footer a {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    font-size: 30px;
    color: #57069e;
  }

  .footer p {
    display: flex;
    justify-content: center;
    align-self: center;
    font-size: 25px;
    font-weight: bold;
    margin-left: 5px;
    margin-top: 10px;
  }

  @media (max-width: 992px) {
    max-width: 1000px !important;
  }
`;
