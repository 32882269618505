import React, { useContext, useState } from 'react';
import { Container } from './styles';
import { Row, Col, Spinner } from 'react-bootstrap';
import {
  IoIosArrowDropleftCircle,
  IoIosArrowDroprightCircle,
  IoIosNotifications,
} from 'react-icons/io';
import { BsChevronDoubleUp } from 'react-icons/bs';
import { PainelNotificacaoProps } from '../../types';
import { Context } from '~/context/auth';
import { Link } from 'react-router-dom';
import { v4 } from 'uuid';
import { removeNotification } from '~/services/user';

const PainelNotificacao: React.FC<PainelNotificacaoProps> = ({
  data,
  message,
}) => {
  const [arrow, setArrow] = useState<number>(0);
  const { handleNotificacao } = useContext(Context);
  if (data?.length === 0 && message.includes('Registros encontrados')) {
    return (
      <Container>
        <div className="w-100 h-100 d-flex justify-conten-center align-items-center">
          <Spinner animation="border" />
        </div>
      </Container>
    );
  }

  const changeNotify = () =>
    message.includes('Registros encontrados') ? (
      <>
        <Row>
          <Col md={1} sm={12}>
            <div className="icon-prioridade">
              <BsChevronDoubleUp />
            </div>
          </Col>
          <Col>
            <Link
              to="/central-notificacao"
              style={{ textDecoration: 'none', color: 'black' }}
              onClick={() => handleNotificacao(data[arrow].cod_notificacao)}
              key={v4()}
            >
              <div key={data[arrow].cod_notificacao} className="container-item">
                <h5>
                  {`#${data[arrow].cod_notificacao} - ${data[arrow].des_nome} `}
                </h5>
                <div
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: data[arrow].des_notificacao,
                  }}
                  style={{ fontSize: '13px' }}
                />
              </div>
            </Link>
          </Col>
        </Row>
      </>
    ) : (
      <>
        <Row>
          <Col>
            <div className="container-item">
              <h5>Nenhuma notificação encontrada para o usuário</h5>
            </div>
          </Col>
        </Row>
      </>
    );

  const handleArrowRight = () => {
    if (arrow < data.length - 1) {
      setArrow(arrow + 1);
    }
  };

  const handleArrowLeft = () => {
    if (arrow > 0) {
      setArrow(arrow - 1);
    }
  };

  return message.includes('Registros encontrados') ? (
    <Container>
      <Row>
        <Col md={2} sm={12}>
          <Link
            to="/central-notificacao"
            style={{ textDecoration: 'none', color: 'black' }}
            onClick={() => removeNotification()}
            key={v4()}
          >
            <div className="notification">
              <div className="icon">
                <IoIosNotifications />
              </div>
              <p>Ver todas...</p>
            </div>
          </Link>
        </Col>
        <Col md={10} sm={12}>
          <div className="painel">
            <Row>
              <Col>
                <div>
                  <button
                    disabled={arrow === 0}
                    type="button"
                    className="icon-arrow"
                  >
                    <IoIosArrowDropleftCircle onClick={handleArrowLeft} />
                  </button>
                </div>
              </Col>
              <Col md={9} sm={12} style={{ padding: '0px 20px' }}>
                {changeNotify()}
              </Col>
              <Col>
                <div>
                  <button
                    disabled={arrow === 9}
                    type="button"
                    className="icon-arrow"
                  >
                    <IoIosArrowDroprightCircle onClick={handleArrowRight} />
                  </button>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Container>
  ) : (
    <Container>
      <Row>
        <Col md={2} sm={12}>
          <Link
            to="/central-notificacao"
            style={{ textDecoration: 'none', color: 'black' }}
            onClick={() => removeNotification()}
            key={v4()}
          >
            <div className="notification">
              <div className="icon">
                <IoIosNotifications />
              </div>
              <p>Ver todas...</p>
            </div>
          </Link>
        </Col>
        <Col md={10} sm={12}>
          <div>
            <Row>
              <Col
                md={9}
                sm={12}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  padding: '5px',
                }}
              >
                {changeNotify()}
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default PainelNotificacao;
