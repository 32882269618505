import { lighten } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  .box-video {
    width: 98%;
    height: 335px;
  }

  .box-video:last-child {
    height: 315px;
  }

  .video {
    width: 100%;
    height: 200px;
  }

  .video iframe {
    width: 100%;
    height: 190px;
  }

  .textVideo {
    width: 100%;
  }
  .textVideo a {
    text-decoration: none;
  }

  .textVideo h5 {
    color: blue;
    font-weight: bold;
  }
  .textVideo p {
    margin-top: -10px;
    line-height: 15px;
    font-size: 16px;
  }
`;
