import React, { useContext } from 'react';

import { Container } from './styles';
import icones from '~/config/icones';
import { Card } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import ListGroup from 'react-bootstrap/ListGroup';
import { nanoid } from 'nanoid';
import { PainelMidiasProps } from '../../types';
import { InformacaoTelasAcessadasContext } from '~/context/InformacaoTelasAcessadas';

const VisitadosRecentemente: React.FC<PainelMidiasProps> = (props) => {
  const { title, data } = props;

  const { addScreenInfo } = useContext(InformacaoTelasAcessadasContext);
  const history = useHistory();
  const getIcon = (libIcon: any, iconDes: any, key: any) => {
    const iconkey = Object.keys(icones).find((icon) => icon === libIcon);
    let Icon = '';
    if (iconkey) {
      const lib = icones[iconkey];
      Icon = lib[iconDes];
    }

    return (
      <>
        <div key={key}>
          <Icon />
        </div>
      </>
    );
  };

  const toTela = (tela: any) => {
    const telaAdd = {
      label: tela.label_menu,
      active: true,
      lib_icon: tela.lib_icon,
      des_icon: tela.des_icon,
      cod_tela: tela.cod_tela,
      url: tela.url,
      linkMaterial: tela.link_material,
      flg_abre_pesquisa: tela.flg_abre_pesquisa,
    };

    addScreenInfo(telaAdd);

    history.push(tela.url ? tela.url : 'app');
  };

  return (
    <Container>
      <Card>
        <Card.Header>{title}</Card.Header>
        <ListGroup variant="flush">
          <div className="containerFlex">
            {data.length > 0 &&
              data.map((element: any, index: any) => {
                return (
                  <ListGroup.Item key={nanoid()}>
                    <div
                      className="link"
                      key={element.label_menu}
                      onClick={() => {
                        toTela(element);
                      }}
                    >
                      <div className="icon">
                        {getIcon(element.lib_icon, element.des_icon, index)}
                      </div>
                      <div className="item">
                        <p>{element.label_menu}</p>
                      </div>
                    </div>
                  </ListGroup.Item>
                );
              })}
          </div>
        </ListGroup>
        {data.length === 0 && (
          <div className="container-empty">
            <p>Nenhuma tela foi encontrada</p>
          </div>
        )}
      </Card>
    </Container>
  );
};

export default VisitadosRecentemente;
